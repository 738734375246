import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/tmp/node_modules/@nib/gatsby-theme-mesh-docs/src/templates/Content.js";
import { Box, Stack, Inline, Tiles } from '@nib/layout';
import Heading from '@nib-components/heading';
import Copy from '@nib-components/copy';
import { TickSystemIcon, CrossInCircleSystemIcon, ChevronRightSystemIcon, ChevronDownSystemIcon } from '@nib/icons';
import ColorPalette, { ColorDot } from '../../src/components/colors/ColorPalette.js';
import AccessibilityMatrix, { MatrixHeader } from '../../src/components/colors/AccessibilityMatrix.js';
import { Link as GatsbyLink } from 'gatsby';
import Link from '@nib-components/link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Alert type="warning" title="This page will soon be deprecated." mdxType="Alert">
  We've released a preview of the next major version of Mesh, incorporating design tokens for a more dynamic and brand-aligned approach to color. Please refer to our <Link component={GatsbyLink} to="/design-tokens/overview/" mdxType="Link">tokens documentation</Link> for more
  information.
    </Alert>
    <h2 {...{
      "id": "core-palette",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#core-palette",
        "aria-label": "core palette permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Core palette`}</h2>
    <p>{`These are the primary colors that should be used in all designs and serve to distinguish digital assets associated with our brand.`}</p>
    <p><strong parentName="p">{`True Green`}</strong>{` `}<ColorDot background="trueGreen" mdxType="ColorDot" />{` should be used as the primary brand color, and can be used anywhere to accentuate our brand identity. `}<strong parentName="p">{`True Green`}</strong>{` `}<ColorDot background="trueGreen" mdxType="ColorDot" />{` should be used for elements like large page `}<a parentName="p" {...{
        "href": "/components/heading/"
      }}>{`Headings`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/button/"
      }}>{`Buttons`}</a>{` and `}<a parentName="p" {...{
        "href": "/components/link/"
      }}>{`Links`}</a>{`. Be cautious not to overuse this color.`}</p>
    <p><strong parentName="p">{`Bright Green`}</strong>{` `}<ColorDot background="brightGreen" mdxType="ColorDot" />{` is our secondary brand color. It should be used cautiously and sparingly as an accent color due to it having an inaccessible `}<a parentName="p" {...{
        "href": "/getting-started/accessibility/accessibility-checklist/#1-sufficient-color-contrast"
      }}>{`color contrast ratio`}</a>{` on lighter backgrounds.`}</p>
    <Tiles space={2} columns={{
      xs: 2,
      lg: 4
    }} mdxType="Tiles">
  <ColorPalette background="trueGreen" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">True Green</Heading>
      <Copy size="small" mdxType="Copy">Token: trueGreen</Copy>
      <Copy size="small" mdxType="Copy">Hex: #144a38</Copy>
    </Stack>
  </ColorPalette>
      <ColorPalette background="brightGreen" mdxType="ColorPalette">
  <Stack space={4} mdxType="Stack">
    <Heading size={4} mdxType="Heading">Bright Green</Heading>
    <Copy size="small" mdxType="Copy">Token: brightGreen</Copy>
    <Copy size="small" mdxType="Copy">Hex: #82e578</Copy>
  </Stack>
      </ColorPalette>
      <ColorPalette background="clearWhite" mdxType="ColorPalette">
  <Stack space={4} mdxType="Stack">
    <Heading size={4} mdxType="Heading">Clear White</Heading>
    <Copy size="small" mdxType="Copy">Token: clearWhite</Copy>
    <Copy size="small" mdxType="Copy">Hex: #ffffff</Copy>
  </Stack>
      </ColorPalette>
  <ColorPalette background="darkest" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Darkest</Heading>
      <Copy size="small" mdxType="Copy">Token: darkest</Copy>
      <Copy size="small" mdxType="Copy">Hex: #434343</Copy>
    </Stack>
  </ColorPalette>
    </Tiles>
    <p>{`In most situations, `}<strong parentName="p">{`Darkest`}</strong>{` `}<ColorDot background="darkest" mdxType="ColorDot" />{` should be used for body text and headings, and `}<strong parentName="p">{`Clear White`}</strong>{` `}<ColorDot background="clearWhite" mdxType="ColorDot" />{` should be considered as the default background color.`}</p>
    <p>{`For key callouts or `}<a parentName="p" {...{
        "href": "/components/section/"
      }}>{`Sections`}</a>{` styled to visually break up long blocks of content, `}<strong parentName="p">{`True Green`}</strong>{` `}<ColorDot background="trueGreen" mdxType="ColorDot" />{` can be used as a background color, as long as you ensure any content within those sections maintains an accessible contrast ratio (see the `}<a parentName="p" {...{
        "href": "#accessibility-tables"
      }}>{`Accessibility tables below`}</a>{`). In this case, you should default to using `}<strong parentName="p">{`Clear White`}</strong>{` `}<ColorDot background="clearWhite" mdxType="ColorDot" />{` as your text color and use `}<strong parentName="p">{`Bright Green`}</strong>{` `}<ColorDot background="brightGreen" mdxType="ColorDot" />{` in situations you would normally use `}<strong parentName="p">{`True Green`}</strong>{` `}<ColorDot background="trueGreen" mdxType="ColorDot" />{` (i.e. large headings, buttons).`}</p>
    <p>{`For different combinations of text and background colors, refer to the `}<a parentName="p" {...{
        "href": "#accessibility-tables"
      }}>{`Accessibility tables below`}</a>{`.`}</p>
    <h2 {...{
      "id": "secondary-palette",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#secondary-palette",
        "aria-label": "secondary palette permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Secondary palette`}</h2>
    <p>{`The secondary palette is a collection of lighter colors that complement our primary palette. It is used to create a more inviting experience by softening elements of the page that may be dominated by the bold primary brand colors.`}</p>
    <Tiles space={2} columns={{
      xs: 2,
      lg: 4
    }} mdxType="Tiles">
  <ColorPalette background="sageGreen" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Sage Green</Heading>
      <Copy size="small" mdxType="Copy">Token: sageGreen</Copy>
      <Copy size="small" mdxType="Copy">Hex: #c2d6b5</Copy>
    </Stack>
  </ColorPalette>
      <ColorPalette background="warmWhite" mdxType="ColorPalette">
  <Stack space={4} mdxType="Stack">
    <Heading size={4} mdxType="Heading">Warm White</Heading>
    <Copy size="small" mdxType="Copy">Token: warmWhite</Copy>
    <Copy size="small" mdxType="Copy">Hex: #f5f0d6</Copy>
  </Stack>
      </ColorPalette>
      <ColorPalette background="sunsetPink" mdxType="ColorPalette">
  <Stack space={4} mdxType="Stack">
    <Heading size={4} mdxType="Heading">Sunset Pink</Heading>
    <Copy size="small" mdxType="Copy">Token: sunsetPink</Copy>
    <Copy size="small" mdxType="Copy">Hex: #fdd6bc</Copy>
  </Stack>
      </ColorPalette>
  <ColorPalette background="lightest" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Lightest</Heading>
      <Copy size="small" mdxType="Copy">Token: lightest</Copy>
      <Copy size="small" mdxType="Copy">Hex: #f1f1f1</Copy>
    </Stack>
  </ColorPalette>
    </Tiles>
    <h2 {...{
      "id": "neutral-palette",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#neutral-palette",
        "aria-label": "neutral palette permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Neutral palette`}</h2>
    <p>{`In many cases, our brand colors may not be suitable for all situations. For these situations, Mesh includes a range of accessible neutral tones to create depth on a page.`}</p>
    <p>{`While `}<strong parentName="p">{`Darkest`}</strong>{` `}<ColorDot background="darkest" mdxType="ColorDot" />{` is our default text color, `}<strong parentName="p">{`Deep Black`}</strong>{` `}<ColorDot background="deepBlack" mdxType="ColorDot" />{` should be considered for small text (≤ `}<inlineCode parentName="p">{`14px`}</inlineCode>{`), disclaimers or other situations where maximum contrast is required.`}</p>
    <Tiles space={2} columns={{
      xs: 2,
      lg: 4
    }} mdxType="Tiles">
  <ColorPalette background="black" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Deep Black</Heading>
      <Copy size="small" mdxType="Copy">Token: black</Copy>
      <Copy size="small" mdxType="Copy">Hex: #141414</Copy>
    </Stack>
  </ColorPalette>
      <ColorPalette background="darkest" mdxType="ColorPalette">
  <Stack space={4} mdxType="Stack">
    <Heading size={4} mdxType="Heading">Darkest</Heading>
    <Copy size="small" mdxType="Copy">Token: darkest</Copy>
    <Copy size="small" mdxType="Copy">Hex: #434343</Copy>
  </Stack>
      </ColorPalette>
      <ColorPalette background="darker" mdxType="ColorPalette">
  <Stack space={4} mdxType="Stack">
    <Heading size={4} mdxType="Heading">Darker</Heading>
    <Copy size="small" mdxType="Copy">Token: darker</Copy>
    <Copy size="small" mdxType="Copy">Hex: #636363</Copy>
  </Stack>
      </ColorPalette>
  <ColorPalette background="dark" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Dark</Heading>
      <Copy size="small" mdxType="Copy">Token: dark</Copy>
      <Copy size="small" mdxType="Copy">Hex: #8a8a8a</Copy>
    </Stack>
  </ColorPalette>
    </Tiles>
    <p>{`Our light palette is intended to be used primarily for background colors. `}<strong parentName="p">{`Clear White`}</strong>{` `}<ColorDot background="clearWhite" mdxType="ColorDot" />{` can also be used as a text color to achieve accessible contrast on sections or components with darker backgrounds.`}</p>
    <p>{`These tones may also be used for non-critical visual elements like container borders, page dividers. zebra-striping table rows, or otherwise to group or separate blocks of content.`}</p>
    <Tiles space={2} columns={{
      xs: 2,
      lg: 4
    }} mdxType="Tiles">
  <ColorPalette background="light" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Light</Heading>
      <Copy size="small" mdxType="Copy">Token: light</Copy>
      <Copy size="small" mdxType="Copy">Hex: #c7c7c7</Copy>
    </Stack>
  </ColorPalette>
      <ColorPalette background="lighter" mdxType="ColorPalette">
  <Stack space={4} mdxType="Stack">
    <Heading size={4} mdxType="Heading">Lighter</Heading>
    <Copy size="small" mdxType="Copy">Token: lighter</Copy>
    <Copy size="small" mdxType="Copy">Hex: #e4e4e4</Copy>
  </Stack>
      </ColorPalette>
      <ColorPalette background="lightest" mdxType="ColorPalette">
  <Stack space={4} mdxType="Stack">
    <Heading size={4} mdxType="Heading">Lightest</Heading>
    <Copy size="small" mdxType="Copy">Token: lightest</Copy>
    <Copy size="small" mdxType="Copy">Hex: #f1f1f1</Copy>
  </Stack>
      </ColorPalette>
      <ColorPalette background="nearWhite" mdxType="ColorPalette">
  <Stack space={4} mdxType="Stack">
    <Heading size={4} mdxType="Heading">Near White</Heading>
    <Copy size="small" mdxType="Copy">Token: nearWhite</Copy>
    <Copy size="small" mdxType="Copy">Hex: #fafafa</Copy>
  </Stack>
      </ColorPalette>
  <ColorPalette background="clearWhite" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Clear White</Heading>
      <Copy size="small" mdxType="Copy">Token: clearWhite</Copy>
      <Copy size="small" mdxType="Copy">Hex: #ffffff</Copy>
    </Stack>
  </ColorPalette>
    </Tiles>
    <h2 {...{
      "id": "functional-palette",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#functional-palette",
        "aria-label": "functional palette permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Functional palette`}</h2>
    <p>{`These colors have semantic meanings, and should be reserved for functional elements or messaging, such as indicating the outcome of an action or highlighting success or failure of a task or interaction. They should be used sparingly so as not to risk confusing their meaning.`}</p>
    <Tiles space={2} columns={{
      xs: 2,
      lg: 4
    }} mdxType="Tiles">
  <ColorPalette background="success" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Success Green</Heading>
      <Copy size="small" mdxType="Copy">Token: success</Copy>
      <Copy size="small" mdxType="Copy">Hex: #007800</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="info" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Info Blue</Heading>
      <Copy size="small" mdxType="Copy">Token: info</Copy>
      <Copy size="small" mdxType="Copy">Hex: #005bb9</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="error" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Error Red</Heading>
      <Copy size="small" mdxType="Copy">Token: error</Copy>
      <Copy size="small" mdxType="Copy">Hex: #c81414</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="warning" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Warning Yellow</Heading>
      <Copy size="small" mdxType="Copy">Token: warning</Copy>
      <Copy size="small" mdxType="Copy">Hex: #ffb400</Copy>
    </Stack>
  </ColorPalette>
    </Tiles>
    <p>{`A summary of how to use these functional colors:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Success Green`}</strong>{` `}<ColorDot background="success" mdxType="ColorDot" />{` should only be used for success alerts or validating form inputs or results.`}</li>
      <li parentName="ul"><strong parentName="li">{`Info Blue`}</strong>{` `}<ColorDot background="info" mdxType="ColorDot" />{` should only be used for information alerts or indicating additional help to forms.`}</li>
      <li parentName="ul"><strong parentName="li">{`Error Red`}</strong>{` `}<ColorDot background="error" mdxType="ColorDot" />{` should only be used for error alerts or validating input or results.`}</li>
      <li parentName="ul"><strong parentName="li">{`Warning Yellow`}</strong>{` `}<ColorDot background="warning" mdxType="ColorDot" />{` should only be used for warning alerts. This color is explicitly not accessible, and should never be used for text. Similarly, any text or iconography overlaid on this color should be dark to meet an appropriate level of contrast.`}</li>
    </ul>
    <p>{`While these colors carry semantic meaning, for accessibility purposes, they should not be relied on to communicate intent. You must ensure your iconography, content and placement of any messaging carries enough meaning that the color only provides additional visual reinforcement of its intent.`}</p>
    <p>{`For focus styles, we use `}<strong parentName="p">{`Accent Blue`}</strong>{` `}<ColorDot background="accent" mdxType="ColorDot" />{` to highlight the active element or field. This color should not be used in any other context.`}</p>
    <Box width="12rem" mdxType="Box">
  <ColorPalette background="accent" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Accent Blue</Heading>
      <Copy size="small" mdxType="Copy">Token: accent</Copy>
      <Copy size="small" mdxType="Copy">Hex: #525199</Copy>
    </Stack>
  </ColorPalette>
    </Box>
    <h2 {...{
      "id": "tints",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tints",
        "aria-label": "tints permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Tints`}</h2>
    <p>{`As an alternative to the core and secondary palettes, Mesh provides a set of `}<a parentName="p" {...{
        "href": "https://maketintsandshades.com/about#calculation-method"
      }}>{`algorithmically generated`}</a>{` tints for each color.`}</p>
    <p>{`These tints should only be used as background colors, and never for text colors in any situation as they do not satisfy our accessibility standards on the majority of backgrounds.`}</p>
    <h3 {...{
      "id": "true-green-tints",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#true-green-tints",
        "aria-label": "true green tints permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`True Green tints`}</h3>
    <Tiles space={2} columns={{
      xs: 2,
      lg: 4
    }} mdxType="Tiles">
  <ColorPalette background="trueGreen80" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">True Green 80%</Heading>
      <Copy size="small" mdxType="Copy">Token: trueGreen80</Copy>
      <Copy size="small" mdxType="Copy">Hex: #436e60</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="trueGreen60" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">True Green 60%</Heading>
      <Copy size="small" mdxType="Copy">Token: trueGreen60</Copy>
      <Copy size="small" mdxType="Copy">Hex: #729288</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="trueGreen40" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">True Green 40%</Heading>
      <Copy size="small" mdxType="Copy">Token: trueGreen40</Copy>
      <Copy size="small" mdxType="Copy">Hex: #a1b7af</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="trueGreen20" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">True Green 20%</Heading>
      <Copy size="small" mdxType="Copy">Token: trueGreen20</Copy>
      <Copy size="small" mdxType="Copy">Hex: #d0dbd7</Copy>
    </Stack>
  </ColorPalette>
    </Tiles>
    <h3 {...{
      "id": "bright-green-tints",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#bright-green-tints",
        "aria-label": "bright green tints permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Bright Green tints`}</h3>
    <Tiles space={2} columns={{
      xs: 2,
      lg: 4
    }} mdxType="Tiles">
  <ColorPalette background="brightGreen80" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Bright Green 80%</Heading>
      <Copy size="small" mdxType="Copy">Token: brightGreen80</Copy>
      <Copy size="small" mdxType="Copy">Hex: #9bea93</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="brightGreen60" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Bright Green 60%</Heading>
      <Copy size="small" mdxType="Copy">Token: brightGreen60</Copy>
      <Copy size="small" mdxType="Copy">Hex: #b4efae</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="brightGreen40" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Bright Green 40%</Heading>
      <Copy size="small" mdxType="Copy">Token: brightGreen40</Copy>
      <Copy size="small" mdxType="Copy">Hex: #cdf5c9</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="brightGreen20" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Bright Green 20%</Heading>
      <Copy size="small" mdxType="Copy">Token: brightGreen20</Copy>
      <Copy size="small" mdxType="Copy">Hex: #e6fae4</Copy>
    </Stack>
  </ColorPalette>
    </Tiles>
    <h3 {...{
      "id": "sage-green-tints",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sage-green-tints",
        "aria-label": "sage green tints permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Sage Green tints`}</h3>
    <Tiles space={2} columns={{
      xs: 2,
      lg: 4
    }} mdxType="Tiles">
  <ColorPalette background="sageGreen80" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Sage Green 80%</Heading>
      <Copy size="small" mdxType="Copy">Token: sageGreen80</Copy>
      <Copy size="small" mdxType="Copy">Hex: #cedec4</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="sageGreen60" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Sage Green 60%</Heading>
      <Copy size="small" mdxType="Copy">Token: sageGreen60</Copy>
      <Copy size="small" mdxType="Copy">Hex: #dae6d3</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="sageGreen40" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Sage Green 40%</Heading>
      <Copy size="small" mdxType="Copy">Token: sageGreen40</Copy>
      <Copy size="small" mdxType="Copy">Hex: #e7efe1</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="sageGreen20" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Sage Green 20%</Heading>
      <Copy size="small" mdxType="Copy">Token: sageGreen20</Copy>
      <Copy size="small" mdxType="Copy">Hex: #f3f7f0</Copy>
    </Stack>
  </ColorPalette>
    </Tiles>
    <h3 {...{
      "id": "warm-white-tints",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#warm-white-tints",
        "aria-label": "warm white tints permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Warm White tints`}</h3>
    <Tiles space={2} columns={{
      xs: 2,
      lg: 4
    }} mdxType="Tiles">
  <ColorPalette background="warmWhite80" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Warm White 80%</Heading>
      <Copy size="small" mdxType="Copy">Token: warmWhite80</Copy>
      <Copy size="small" mdxType="Copy">Hex: #f7f3de</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="warmWhite60" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Warm White 60%</Heading>
      <Copy size="small" mdxType="Copy">Token: warmWhite60</Copy>
      <Copy size="small" mdxType="Copy">Hex: #f9f6e6</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="warmWhite40" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Warm White 40%</Heading>
      <Copy size="small" mdxType="Copy">Token: warmWhite40</Copy>
      <Copy size="small" mdxType="Copy">Hex: #fbf9ef</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="warmWhite20" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Warm White 20%</Heading>
      <Copy size="small" mdxType="Copy">Token: warmWhite20</Copy>
      <Copy size="small" mdxType="Copy">Hex: #fdfcf7</Copy>
    </Stack>
  </ColorPalette>
    </Tiles>
    <h3 {...{
      "id": "sunset-pink-tints",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sunset-pink-tints",
        "aria-label": "sunset pink tints permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Sunset Pink tints`}</h3>
    <Tiles space={2} columns={{
      xs: 2,
      lg: 4
    }} mdxType="Tiles">
  <ColorPalette background="sunsetPink80" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Sunset Pink 80%</Heading>
      <Copy size="small" mdxType="Copy">Token: sunsetPink80</Copy>
      <Copy size="small" mdxType="Copy">Hex: #fddec9</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="sunsetPink60" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Sunset Pink 60%</Heading>
      <Copy size="small" mdxType="Copy">Token: sunsetPink60</Copy>
      <Copy size="small" mdxType="Copy">Hex: #fee6d7</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="sunsetPink40" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Sunset Pink 40%</Heading>
      <Copy size="small" mdxType="Copy">Token: sunsetPink</Copy>
      <Copy size="small" mdxType="Copy">Hex: #feefe4</Copy>
    </Stack>
  </ColorPalette>
  <ColorPalette background="sunsetPink20" mdxType="ColorPalette">
    <Stack space={4} mdxType="Stack">
      <Heading size={4} mdxType="Heading">Sunset Pink 20%</Heading>
      <Copy size="small" mdxType="Copy">Token: sunsetPink20</Copy>
      <Copy size="small" mdxType="Copy">Hex: #fff7f2</Copy>
    </Stack>
  </ColorPalette>
    </Tiles>
    <h2 {...{
      "id": "accessibility-tables",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#accessibility-tables",
        "aria-label": "accessibility tables permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Accessibility tables`}</h2>
    <p>{`To ensure accessible use of our text colors on a range of backgrounds, refer to the tables below for minimum ratings against WCAG's accessibility standards. Colors that are not listed as a `}<inlineCode parentName="p">{`Foreground`}</inlineCode>{` color should never be used as text.`}</p>
    <AccessibilityMatrix mdxType="AccessibilityMatrix">
  <Box background="nearWhite" style={{
        minWidth: '175px'
      }} mdxType="Box">
    <MatrixHeader padding={4} color="trueGreen" mdxType="MatrixHeader">
      <Copy mdxType="Copy">Background</Copy>
    </MatrixHeader>
    <Box padding={4} color="trueGreen" mdxType="Box">
      <Copy mdxType="Copy">Foreground</Copy>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">True Green</Copy>
        <ColorDot background="trueGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Bright Green</Copy>
        <ColorDot background="brightGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Deep Black</Copy>
        <ColorDot background="deepBlack" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Darkest</Copy>
        <ColorDot background="darkest" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Clear White</Copy>
        <ColorDot background="clearWhite" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Success Green</Copy>
        <ColorDot background="success" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Info Blue</Copy>
        <ColorDot background="info" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Error Red</Copy>
        <ColorDot background="error" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Warning Yellow</Copy>
        <ColorDot background="warning" mdxType="ColorDot" />
      </Inline>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Clear White</Copy>
        <ColorDot background="clearWhite" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="clearWhite" mdxType="Box">
      <Box padding={4} color="clearWhite" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="success" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="info" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="error" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">True Green</Copy>
        <ColorDot background="trueGreen" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="trueGreen" color="clearWhite" mdxType="Box">
      <Box padding={4} color="trueGreen" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="brightGreen" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="warning" mdxType="Box">
        AA Normal
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Bright Green</Copy>
        <ColorDot background="brightGreen" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="brightGreen" color="deepBlack" mdxType="Box">
      <Box padding={4} color="brightGreen" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Sage Green</Copy>
        <ColorDot background="sageGreen" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="sageGreen" mdxType="Box">
      <Box padding={4} color="sageGreen" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="success" mdxType="Box">
        AA Large
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="info" mdxType="Box">
        AA Large
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="error" mdxType="Box">
        AA Large
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Warm White</Copy>
        <ColorDot background="warmWhite" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="warmWhite" mdxType="Box">
      <Box padding={4} color="warmWhite" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="success" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="info" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="error" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Sunset Pink</Copy>
        <ColorDot background="sunsetPink" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="sunsetPink" mdxType="Box">
      <Box padding={4} color="sunsetPink" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="success" mdxType="Box">
        AA Large
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="info" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="error" mdxType="Box">
        AA Large
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Lightest</Copy>
        <ColorDot background="lightest" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="lightest" mdxType="Box">
      <Box padding={4} color="lightest" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="success" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="info" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="error" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
    </AccessibilityMatrix>
    <h3 {...{
      "id": "tints-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#tints-1",
        "aria-label": "tints 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Tints`}</h3>
    <h4 {...{
      "id": "true-green",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#true-green",
        "aria-label": "true green permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`True Green`}</h4>
    <AccessibilityMatrix mdxType="AccessibilityMatrix">
  <Box background="nearWhite" style={{
        minWidth: '175px'
      }} mdxType="Box">
    <MatrixHeader padding={4} color="trueGreen" mdxType="MatrixHeader">
      <Copy mdxType="Copy">Background</Copy>
    </MatrixHeader>
    <Box padding={4} color="trueGreen" mdxType="Box">
      <Copy mdxType="Copy">Foreground</Copy>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">True Green</Copy>
        <ColorDot background="trueGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Bright Green</Copy>
        <ColorDot background="brightGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Deep Black</Copy>
        <ColorDot background="deepBlack" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Darkest</Copy>
        <ColorDot background="darkest" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Clear White</Copy>
        <ColorDot background="clearWhite" mdxType="ColorDot" />
      </Inline>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">True Green 80%</Copy>
        <ColorDot background="trueGreen80" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="trueGreen80" color="clearWhite" mdxType="Box">
      <Box padding={4} color="trueGreen80" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="brightGreen" mdxType="Box">
        AA Large
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        AA Large
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        AA Normal
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">True Green 60%</Copy>
        <ColorDot background="trueGreen60" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="trueGreen60" color="clearWhite" mdxType="Box">
      <Box padding={4} color="trueGreen60" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        AA Large
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">True Green 40%</Copy>
        <ColorDot background="trueGreen40" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="trueGreen40" color="deepBlack" mdxType="Box">
      <Box padding={4} color="trueGreen40" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">True Green 20%</Copy>
        <ColorDot background="trueGreen20" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="trueGreen20" color="deepBlack" mdxType="Box">
      <Box padding={4} color="trueGreen20" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
    </AccessibilityMatrix>
    <h4 {...{
      "id": "bright-green",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#bright-green",
        "aria-label": "bright green permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Bright Green`}</h4>
    <AccessibilityMatrix mdxType="AccessibilityMatrix">
  <Box background="nearWhite" style={{
        minWidth: '175px'
      }} mdxType="Box">
    <MatrixHeader padding={4} color="trueGreen" mdxType="MatrixHeader">
      <Copy mdxType="Copy">Background</Copy>
    </MatrixHeader>
    <Box padding={4} color="trueGreen" mdxType="Box">
      <Copy mdxType="Copy">Foreground</Copy>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">True Green</Copy>
        <ColorDot background="trueGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Bright Green</Copy>
        <ColorDot background="brightGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Deep Black</Copy>
        <ColorDot background="deepBlack" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Darkest</Copy>
        <ColorDot background="darkest" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Clear White</Copy>
        <ColorDot background="clearWhite" mdxType="ColorDot" />
      </Inline>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Bright Green 80%</Copy>
        <ColorDot background="brightGreen80" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="brightGreen80" color="deepBlack" mdxType="Box">
      <Box padding={4} color="brightGreen80" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Bright Green 60%</Copy>
        <ColorDot background="brightGreen60" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="brightGreen60" color="deepBlack" mdxType="Box">
      <Box padding={4} color="brightGreen60" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Bright Green 40%</Copy>
        <ColorDot background="brightGreen40" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="brightGreen40" color="deepBlack" mdxType="Box">
      <Box padding={4} color="brightGreen40" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Bright Green 20%</Copy>
        <ColorDot background="brightGreen40" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="brightGreen20" color="deepBlack" mdxType="Box">
      <Box padding={4} color="brightGreen20" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
    </AccessibilityMatrix>
    <h4 {...{
      "id": "sage-green",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#sage-green",
        "aria-label": "sage green permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Sage Green`}</h4>
    <AccessibilityMatrix mdxType="AccessibilityMatrix">
  <Box background="nearWhite" style={{
        minWidth: '175px'
      }} mdxType="Box">
    <MatrixHeader padding={4} color="trueGreen" mdxType="MatrixHeader">
      <Copy mdxType="Copy">Background</Copy>
    </MatrixHeader>
    <Box padding={4} color="trueGreen" mdxType="Box">
      <Copy mdxType="Copy">Foreground</Copy>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">True Green</Copy>
        <ColorDot background="trueGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Bright Green</Copy>
        <ColorDot background="brightGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Deep Black</Copy>
        <ColorDot background="deepBlack" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Darkest</Copy>
        <ColorDot background="darkest" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Clear White</Copy>
        <ColorDot background="clearWhite" mdxType="ColorDot" />
      </Inline>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Sage Green 80%</Copy>
        <ColorDot background="sageGreen80" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="sageGreen80" color="deepBlack" mdxType="Box">
      <Box padding={4} color="sageGreen80" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Sage Green 60%</Copy>
        <ColorDot background="sageGreen60" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="sageGreen60" color="deepBlack" mdxType="Box">
      <Box padding={4} color="sageGreen60" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Sage Green 40%</Copy>
        <ColorDot background="sageGreen40" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="sageGreen40" color="deepBlack" mdxType="Box">
      <Box padding={4} color="sageGreen40" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Sage Green 20%</Copy>
        <ColorDot background="sageGreen20" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="sageGreen20" color="deepBlack" mdxType="Box">
      <Box padding={4} color="sageGreen20" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
    </AccessibilityMatrix>
    <h4 {...{
      "id": "warm-white",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#warm-white",
        "aria-label": "warm white permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Warm White`}</h4>
    <AccessibilityMatrix mdxType="AccessibilityMatrix">
  <Box background="nearWhite" style={{
        minWidth: '175px'
      }} mdxType="Box">
    <MatrixHeader padding={4} color="trueGreen" mdxType="MatrixHeader">
      <Copy mdxType="Copy">Background</Copy>
    </MatrixHeader>
    <Box padding={4} color="trueGreen" mdxType="Box">
      <Copy mdxType="Copy">Foreground</Copy>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">True Green</Copy>
        <ColorDot background="trueGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Bright Green</Copy>
        <ColorDot background="brightGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Deep Black</Copy>
        <ColorDot background="deepBlack" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Darkest</Copy>
        <ColorDot background="darkest" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Clear White</Copy>
        <ColorDot background="clearWhite" mdxType="ColorDot" />
      </Inline>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Warm White 80%</Copy>
        <ColorDot background="warmWhite80" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="warmWhite80" color="deepBlack" mdxType="Box">
      <Box padding={4} color="warmWhite80" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Warm White 60%</Copy>
        <ColorDot background="warmWhite60" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="warmWhite60" color="deepBlack" mdxType="Box">
      <Box padding={4} color="warmWhite60" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Warm White 40%</Copy>
        <ColorDot background="warmWhite40" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="warmWhite40" color="deepBlack" mdxType="Box">
      <Box padding={4} color="warmWhite40" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Warm White 20%</Copy>
        <ColorDot background="warmWhite20" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="warmWhite20" color="deepBlack" mdxType="Box">
      <Box padding={4} color="warmWhite20" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
    </AccessibilityMatrix>
    <h4 {...{
      "id": "sunset-pink",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#sunset-pink",
        "aria-label": "sunset pink permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Sunset Pink`}</h4>
    <AccessibilityMatrix mdxType="AccessibilityMatrix">
  <Box background="nearWhite" style={{
        minWidth: '175px'
      }} mdxType="Box">
    <MatrixHeader padding={4} color="trueGreen" mdxType="MatrixHeader">
      <Copy mdxType="Copy">Background</Copy>
    </MatrixHeader>
    <Box padding={4} color="trueGreen" mdxType="Box">
      <Copy mdxType="Copy">Foreground</Copy>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">True Green</Copy>
        <ColorDot background="trueGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Bright Green</Copy>
        <ColorDot background="brightGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Deep Black</Copy>
        <ColorDot background="deepBlack" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Darkest</Copy>
        <ColorDot background="darkest" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Clear White</Copy>
        <ColorDot background="clearWhite" mdxType="ColorDot" />
      </Inline>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Sunset Pink 80%</Copy>
        <ColorDot background="sunsetPink80" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="sunsetPink80" color="deepBlack" mdxType="Box">
      <Box padding={4} color="sunsetPink80" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Sunset Pink 60%</Copy>
        <ColorDot background="sunsetPink60" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="sunsetPink60" color="deepBlack" mdxType="Box">
      <Box padding={4} color="sunsetPink60" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Sunset Pink 40%</Copy>
        <ColorDot background="sunsetPink40" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="sunsetPink40" color="deepBlack" mdxType="Box">
      <Box padding={4} color="sunsetPink40" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Sunset Pink 20%</Copy>
        <ColorDot background="sunsetPink20" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="sunsetPink20" color="deepBlack" mdxType="Box">
      <Box padding={4} color="sunsetPink20" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
    </AccessibilityMatrix>
    <h4 {...{
      "id": "neutrals-light",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#neutrals-light",
        "aria-label": "neutrals light permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "width": "16px",
          "height": "16px",
          "viewBox": "0 0 24 24",
          "version": "1.1",
          "xmlns": "http://www.w3.org/2000/svg",
          "xmlnsXLink": "http://www.w3.org/1999/xlink"
        }}>{`
                `}<g parentName="svg" {...{
            "id": "Icon/System/Hyperlink",
            "stroke": "none",
            "strokeWidth": "1",
            "fill": "none",
            "fillRule": "evenodd"
          }}>{`
                    `}<g parentName="g" {...{
              "id": "bounding-box"
            }}>{`
                        `}<polygon parentName="g" {...{
                "id": "shape",
                "points": "0 0 24 0 24 24 0 24"
              }}></polygon>{`
                    `}</g>{`
                    `}<path parentName="g" {...{
              "d": "M2.97056705,10.5950614 C4.54173031,7.27887278 8.49625508,5.854302 11.8239821,7.40797375 C13.7163188,8.30070362 15.0784176,10.0316702 15.5010931,12.0808775 C15.5847686,12.4865513 15.3237374,12.8832474 14.9180635,12.9669229 C14.5123896,13.0505983 14.1156936,12.7895671 14.0320181,12.3838932 C13.7046432,10.7967205 12.6496566,9.45603463 11.1866976,8.76586164 C8.60932967,7.56252773 5.54399976,8.66677658 4.32696827,11.2355079 L4.32696827,11.2355079 L2.53228159,15.0495337 C1.32882051,17.6273078 2.43304674,20.6930819 4.99986835,21.9096136 C7.57764239,23.1130747 10.6434165,22.0088484 11.8617986,19.4380931 C12.0391957,19.0637896 12.4864375,18.9041655 12.860741,19.0815627 C13.2350445,19.2589598 13.3946687,19.7062016 13.2172715,20.0805051 C11.6455484,23.3968011 7.69067395,24.8212622 4.36139006,23.2669369 C1.0450941,21.6952138 -0.379367033,17.7403393 1.17407024,14.4129359 L1.17407024,14.4129359 Z M10.8106553,3.99447201 C12.3823784,0.678176058 16.3372528,-0.746285079 19.6665996,0.808070034 C22.9820653,2.37977643 24.4063771,6.33358241 22.8538565,9.66074191 L22.8538565,9.66074191 L21.0573195,13.4787014 C19.4855963,16.7949973 15.5307219,18.2194585 12.2029859,16.6658646 C10.5847239,15.9034254 9.34257221,14.5202641 8.75785805,12.8296445 C8.62246787,12.4381826 8.83005498,12.0110847 9.22151682,11.8756945 C9.61297867,11.7403043 10.0400766,11.9478914 10.1754668,12.3393533 C10.6280876,13.6480432 11.5896234,14.718733 12.8399163,15.3078099 C15.4176903,16.5112709 18.4834644,15.4070447 19.7009585,12.8381699 L19.7009585,12.8381699 L21.4955823,9.02427881 C22.698284,6.44679832 21.5941735,3.38185245 19.0280584,2.16536354 C16.4502844,0.961902462 13.3845103,2.06612869 12.1661282,4.63688397 C11.988731,5.01118749 11.5414893,5.17081162 11.1671857,4.99341446 C10.7928822,4.8160173 10.6332581,4.36877553 10.8106553,3.99447201 Z",
              "id": "Combined-Shape",
              "fill": "#444444",
              "fillRule": "nonzero"
            }}></path>{`
                `}</g>{`
              `}</svg></a>{`Neutrals (light)`}</h4>
    <AccessibilityMatrix mdxType="AccessibilityMatrix">
  <Box background="nearWhite" style={{
        minWidth: '175px'
      }} mdxType="Box">
    <MatrixHeader padding={4} color="trueGreen" mdxType="MatrixHeader">
      <Copy mdxType="Copy">Background</Copy>
    </MatrixHeader>
    <Box padding={4} color="trueGreen" mdxType="Box">
      <Copy mdxType="Copy">Foreground</Copy>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">True Green</Copy>
        <ColorDot background="trueGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Bright Green</Copy>
        <ColorDot background="brightGreen" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Deep Black</Copy>
        <ColorDot background="deepBlack" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Darkest</Copy>
        <ColorDot background="darkest" mdxType="ColorDot" />
      </Inline>
    </Box>
    <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Clear White</Copy>
        <ColorDot background="clearWhite" mdxType="ColorDot" />
      </Inline>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Light</Copy>
        <ColorDot background="light" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="light" color="deepBlack" mdxType="Box">
      <Box padding={4} color="light" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        AA Normal
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Lighter</Copy>
        <ColorDot background="lighter" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="lighter" color="deepBlack" mdxType="Box">
      <Box padding={4} color="lighter" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Lightest</Copy>
        <ColorDot background="lightest" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="lightest" color="deepBlack" mdxType="Box">
      <Box padding={4} color="lightest" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Near White</Copy>
        <ColorDot background="nearWhite" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="nearWhite" color="deepBlack" mdxType="Box">
      <Box padding={4} color="nearWhite" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
  <Box style={{
        minWidth: '190px'
      }} mdxType="Box">
    <MatrixHeader padding={4} background="nearWhite" mdxType="MatrixHeader">
      <Inline space={2} mdxType="Inline">
        <Copy mdxType="Copy">Clear White</Copy>
        <ColorDot background="clearWhite" mdxType="ColorDot" />
      </Inline>
    </MatrixHeader>
    <Box background="clearWhite" color="deepBlack" mdxType="Box">
      <Box padding={4} color="clearWhite" mdxType="Box">
        <Copy mdxType="Copy">-</Copy>
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="trueGreen" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="deepBlack" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} color="darkest" mdxType="Box">
        <TickSystemIcon size="xs" mdxType="TickSystemIcon" />
      </Box>
      <Box paddingVertical={2} paddingHorizontal={4} mdxType="Box">
        <CrossInCircleSystemIcon size="xs" mdxType="CrossInCircleSystemIcon" />
      </Box>
    </Box>
  </Box>
    </AccessibilityMatrix>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      